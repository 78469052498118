import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

@Injectable()
export class GlobalLoaderService {

  private loading: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController
  ) {
  }

  dismiss() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  async prepare(options: LoadingOptions) {
    if (this.loading) {
      this.loading.dismiss();
    }

    this.loading = await this.loadingController.create(options);
  }

  show() {
    if (this.loading) {
      this.loading.present();
    }
  }

}