export interface ErrorLog {
  error: string;
  // hash?: string;
  level: number;
  localdt: number;
}

export class LogLevel {
  static readonly DEBUG = 10;
  static readonly INFO = 20;
  static readonly WARN = 30;
  static readonly ERROR = 40;
  static readonly FATAL = 50;
}

export interface ServerErrorLog {
  hash: string;
  dt: string;
  error: string;
  localdt: string;
  level: number;
  source: string;
}