import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  static readonly GAME_ID = 'game_id';
  static readonly PLAYER_HASH = 'player_hash';
  static readonly PASSWORD = 'password';
  static readonly TOKEN = 'token';
  static readonly SETTINGS_ENABLED = 'settings-enabled';
  static readonly DISCLAIMER = 'disclaimer';
  static readonly SUPERADMIN = 'superadmin';
  // readonly FITNESS = 'fitness';

  clear() {
    localStorage.clear();
  }

  load(key: string): string {
    return localStorage.getItem(key);
  }

  save(key: string, value: string) {
    localStorage.setItem(key, value);
  }


  sessionLoad(key: string): string {
    return sessionStorage.getItem(key);
  }


  sessionSave(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }
}
