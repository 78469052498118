import { Injectable } from "@angular/core";
import { timeout } from "rxjs/operators";
import { BasicResponse } from "../interfaces/basic-response.interface";
import { ErrorLog } from "../interfaces/error-log.interface";
import { ApiService } from "./api.service";

@Injectable()
export class ErrorLogService extends ApiService {

  log: ErrorLog[] = [];
  timerHandle: number;
  sendInProgress = false;

  add(error: string, level: number) {
    let row: ErrorLog = {
      error: error,
      level: level,
      localdt: this.getTime(),
    } as ErrorLog;
    
    console.log(error);
    this.log.push( row );
  }


  getTime(): number {
    return Math.round( new Date().getTime() / 1000 );
  }

  
  send() {
    if (!this.log.length) {
      return;
    }

    if (this.sendInProgress) {
      return;
    }

    let data = {
      errors : this.log.slice(0)
    };

    let lastEntryIndex = data.errors.length;

    this.http.post<BasicResponse>( this.configService.getValue('host') + 'api/game/errorLog', data)
      .pipe(
        timeout(9000),
      )
      .toPromise()
      .then(
        (success: BasicResponse) => {
          this.log.splice(0, lastEntryIndex);
        }
      )
      .catch((error: any) => {
        // irony
      })
      .finally(() => {
        this.sendInProgress = false;
      });
  }


  start() {
    console.log('ErrorLogService active');
    if (this.timerHandle) {
      this.stop();
    }

    setInterval( () => {
      this.send();
    }, 10000);
  }


  stop() {
    console.log('ErrorLogService stopped');
    clearInterval(this.timerHandle);
  }

}