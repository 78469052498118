import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable()
export class BasicInterceptor implements HttpInterceptor {

    constructor(
        private storage: StorageService        
    ) {
        
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('Interceptor ran: ', request.url);
        let token = this.storage.sessionLoad(StorageService.TOKEN);

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization : 'bearer ' + token
                }
            });
        }

        return next.handle(request);
    }
}