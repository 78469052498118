import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(
    protected configService: ConfigService,
    protected http: HttpClient
  ) {}

  static readonly GET = 'get';
  static readonly POST = 'post';

  static readonly E_INVALID_SESSION = 'invalid-session';

  
  simpleQuery<T>(method: string, controllerMethod: string, payload: any = null, timeoutMilliseconds: number = 30000): Observable<T> {
    switch (method) {
      case ApiService.GET:
        return this.http.get<T>( this.configService.getValue('host') + `api/${controllerMethod}`, { params : payload })
        .pipe(
          timeout(timeoutMilliseconds),
          retry(2)
        );
        break;
      case ApiService.POST:
        return this.http.post<T>( this.configService.getValue('host') + `api/${controllerMethod}`, payload)
        .pipe(
          timeout(timeoutMilliseconds),
          retry(2)
        );
        break;
      default:
        console.error('simpleQuery: no valid method passed in! Received: ', method);
        break;
    }
  }
}