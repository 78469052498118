import { Component, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ErrorLogService } from './shared/services/error-log.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  noShadowDomSupport = false;

  constructor(
    private element: ElementRef,
    private errorLogService: ErrorLogService,
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar
  ) {
    this.initializeApp();
    this.errorLogService.start();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      // mobile iOS 9 doesn't support the shadow dom
      // so this adds a class to allow specific CSS selectors to activate
      if(!this.element.nativeElement.createShadowRoot && !this.element.nativeElement.attachShadow) {
        this.noShadowDomSupport = true;
      }
    
    });
  }
}
