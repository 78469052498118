import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ConfigService } from './services/config.service';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotifyService } from './services/notify.service';
import { BasicInterceptor } from './interceptors/basic.interceptor';
import { GlobalLoaderService } from './services/global-loader.service';
import { ErrorMessageService } from './services/error-message.service';
import { ApiService } from './services/api.service';
import { MapService } from './services/map.service';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { UtilService } from './services/util.service';
import { ErrorLogService } from './services/error-log.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [
    SocialShareComponent,
  ],
  providers : [
    ApiService,
    AuthService,
    ConfigService,
    DatePipe,
    ErrorLogService,
    ErrorMessageService,
    GlobalLoaderService,
    MapService,
    NotifyService,
    StorageService,
    UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicInterceptor,
      multi: true
    }
  ],
  exports: [
    SocialShareComponent
  ]
})
export class SharedModule {}
