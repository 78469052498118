import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  readonly REAUTH_TIMEOUT = 60;

  canActivateSubject = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const lastAuthenticated = this.authService.lastAuthenticated;
    const now: number = Math.round( (new Date().getTime()) / 1000 );
    const gameName: string = this.storageService.load(StorageService.GAME_ID);
    const password: string = this.storageService.load(StorageService.PASSWORD);
    const playerHash: string = this.storageService.load(StorageService.PLAYER_HASH);
    
    if (!gameName) {
      console.warn('Auth failed on gameId check');
      return this.failedAuth();
    }

    if ((lastAuthenticated + this.REAUTH_TIMEOUT) > now) {
      // console.log('Auth passed: authenticated recently', lastAuthenticated + this.REAUTH_TIMEOUT, now);
      return of(true);
    }

    this.doVerification(gameName, password, playerHash);

    return this.canActivateSubject;
  }

  /*
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
  */

  async doVerification(gameName: string, password: string, playerHash: string) {
    let success: boolean;

    try {
      success = await this.verifySession(gameName, playerHash);

      if (success) {
        this.canActivateSubject.next(true);
        return;
      }
  
      success = await this.verifyPassword(gameName, password);
      
      if (success) {
        this.canActivateSubject.next(true);
        return;
      }
      
    } catch (error) {
      this.failedAuth();
      return;
    }

    this.failedAuth();
  }
  
  failedAuth(): Observable<boolean> {
    console.log('failed auth!');
    this.router.navigate(['/home']);
    //return of(false);
    this.canActivateSubject.next(false);
    return of(false);
  }

  
  verifyPassword(gameId: string, password: string): Promise<boolean> {
    const promise =
      this.authService.verifyPassword(gameId, password).toPromise();
    
    promise
      .then( (success: boolean) => {
        return success;
      })
    
    return promise;
  }

  async verifySession(gameName: string, playerHash: string): Promise<boolean> {
    const promise = 
      this.authService.verifySession(gameName, playerHash).toPromise();
      
    promise
      .then( (success:boolean) => {
        return success;
      });
      
    return promise;
  }
}
