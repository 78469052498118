import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'home/:gameName', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'game', loadChildren: () => import('./game/game.module').then(m => m.GamePageModule), canActivate: [AuthGuard] },
  { path: 'stats/:roundId/:roundNumber', loadChildren: () => import('./stats/stats.module').then(m => m.StatsPageModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule) },
  { path: 'winners', loadChildren: () => import('./winners/winners.module').then(m => m.WinnersPageModule) },
  /*
  { path: 'play', loadChildren: './play/play.module#PlayPageModule' },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
