import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { retry, map, catchError, timeout } from 'rxjs/operators';
import { GameLoginResponse } from 'src/app/shared/interfaces/game-login-response.interface';
import { StorageService } from "./storage.service";

@Injectable()
export class AuthService {

  lastError = '';
  isHost: boolean = false;
  lastAuthenticated: number = 0;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private storage: StorageService
  ){}


  sessionExpired() {
    this.lastAuthenticated = 0;
  }


  updateLastAuthenticated() {
    this.lastAuthenticated = Math.round( new Date().getTime() / 1000 );
  }
  

  verifyPassword(gameName: string, password: string): Observable<boolean> {
    let options:any = {
      gameName, password
    };

    const result = this.http
      .post( this.configService.getValue('host') + 'api/game/join', options)
      .pipe(
        timeout(6000),
        retry(2),
        map( (response: GameLoginResponse) => {
          const success = (response.success ? true : false);

          if (success) {
            this.updateLastAuthenticated();
            this.isHost = response.isHost;
            this.storage.sessionSave(StorageService.TOKEN, response.token);
          } else {
            this.lastError = response.message;
          }
          
          return success;
        }),
        catchError( (error: HttpErrorResponse) => {
          return throwError('Could not reach server. '+error.message);
        })
      );
  
    return result;
  }

  
  verifySession(gameName: string, playerHash: string): Observable<boolean> {
    let options:any = {
      gameName, playerHash
    };

    const result = this.http
      .post( this.configService.getValue('host') + 'api/game/verifySession', options)
      .pipe(
        retry(3),
        map( (response: GameLoginResponse) => {
          const success = (response.success ? true : false);

          if (success) {
            this.updateLastAuthenticated();
            this.isHost = response.isHost;
            this.storage.sessionSave(StorageService.TOKEN, response.token);
          }
          
          return success;
        }),
        catchError( (error: HttpErrorResponse) => {
          return throwError('Could not reach server. '+error.message);
        })
      );
  
    return result;

  }
}