import { Injectable } from "@angular/core";
import { ToastController, AlertController, ModalController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable()
export class NotifyService {
  constructor(
    private alertController: AlertController,
    private toastController: ToastController
  ) {}

  
  /**
   * Shows an alert dialog

   * @param header 
   * @param message 
   * @param confirmValue  set this to false and you will only see an OK button, as the
   *                      ok and cancel buttons will return the same value
   * @returns 
   */
  alert(header: string, message: string, confirmValue: any = false): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      let buttons = [
        {
          text: 'OK',
          handler: () => resolve(confirmValue)
        }        
      ];

      if (confirmValue !== false) {
        buttons.push(
          {
            text: 'Cancel',
            handler: () => resolve(false)
          },
        );
      }
  
      this.alertController.create({
        header: header,
        message: message,
        buttons: buttons
      }).then( (alert: HTMLIonAlertElement) => alert.present() );
    });

    return promise;
  }

  
  /**
   * shows a toast
   *
   * @param message 
   * @param duration 
   * @param aboveFooter 
   * @returns 
   */
  async show(message: string, duration: number = null, aboveFooter = false): Promise<HTMLIonToastElement> {
    if (duration === null) {
      duration = 4000;
    }

    let options: ToastOptions = {
      message : message,
      duration: duration,
      position : 'bottom',
      cssClass: '',
    };

    if (aboveFooter) {
      options.cssClass = 'above-footer';
    }

    let toast = await this.toastController.create( options );
    toast.present();
    
    return toast;
  }


  
  /**
   * shows a warning toast
   *
   * @param message 
   * @param aboveFooter 
   */
  warning(message: string, aboveFooter = false) {
    let options: ToastOptions = {
      message : message,
      duration : 2500,
      position : 'bottom',
      cssClass : '',
      color: 'danger'
    };

    if (aboveFooter) {
      options.cssClass = 'above-footer';
    }

    this.toastController.create( options )
      .then( (toast) => {
        toast.present();
      });

  }

  
}