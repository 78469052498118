import { Injectable } from '@angular/core';
import View from 'ol/View';
import { MapDirective } from '../directives/map/map';
import { TagLocation } from '../../shared/interfaces/tag-location.interface';
import { PlayerModel } from '../../shared/models/player.model';
import { LatLon } from 'src/app/shared/interfaces/latlon.interface';


@Injectable()
export class MapService {

  firstLocation = true;
  lastKnownLocation: LatLon = null;
  mapDirective: MapDirective;
  taggerHashes: any = {};

  constructor(
  ) { }

  static degreesToRadians(degrees: number) {
    // return degrees * 0.0174532925199;
    return degrees * 0.01745323;
  }
  
  
  /*
  getDistance(coord1:number[], coord2:number[]):number {
    let lonlat1:number[] = toLonLat(coord1);
    let lonlat2:number[] = toLonLat(coord2);

    let lat1:number = lonlat1[1];
    let lon1:number = lonlat1[0];
    let lat2:number = lonlat2[1];
    let lon2:number = lonlat2[0];
  
    return this.getDistanceUsingLatLon(lat1, lon1, lat2, lon2);
  }
  */

  
  static getDistanceUsingLatLon(lat1: number, lon1: number, lat2: number, lon2: number) {
    let earthRadius = 6371000;

    let dLat = MapService.degreesToRadians(lat2 - lat1);
    let dLon = MapService.degreesToRadians(lon2 - lon1);

    lat1 = MapService.degreesToRadians(lat1);
    lat2 = MapService.degreesToRadians(lat2);

    let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    let rawResult = (earthRadius * c);

    return parseInt( rawResult.toFixed(0) );
  }

  
  getMapView(): View {
    return this.mapDirective.olView;
  }

  
  locationChanged(geolocation: GeolocationPosition) {
    let latlon: LatLon = {
      lat: geolocation.coords.latitude,
      lon: geolocation.coords.longitude
    } as LatLon;

    this.lastKnownLocation = latlon;

    this.mapDirective.setLocation(geolocation);

    if (this.firstLocation){
      this.recentreOnLastKnownLocation();
      this.firstLocation = false;
    }
  }

  
  recentre(latlon: LatLon) {
    this.mapDirective.zeroMap(latlon);
  }


  recentreOnLastKnownLocation() {
    if (!this.lastKnownLocation) {
      return;
    }
    
    this.mapDirective.zeroMap( this.lastKnownLocation );
  }
  

  renderMap() {
    this.mapDirective.renderMap();
  }

  
  renderTaggerLocations(locations: TagLocation[]): number {
    let totalRendered = 0;
    locations.forEach((location: TagLocation) => {
      if (this.mapDirective.renderLocation(location.id, location.latlon.lat, location.latlon.lon)){
        totalRendered++;
      }
    });

    return totalRendered;
  }

  
  removeLocation(location: TagLocation) {
    this.mapDirective.removeLocation(location.id);
  }

  
  removePlayer(player:PlayerModel){
    this.mapDirective.removePlayer(player);
  }


  reset() {
    // console.log('MapService: reset');
    this.firstLocation = true;
    this.taggerHashes = {};
    this.mapDirective.removeAllLocations();
  }


  setMapDirective(map: MapDirective) {
    if (!map) {
      console.warn('map.service: map direction passed in is invalid: ', map);
    }
    this.mapDirective = map;
  }

  
  updatePlayerLocation(tmpPlayer:PlayerModel){
    // DO NOT STORE THE PLAYER MODEL PASSED IN HERE
    if (!this.taggerHashes[ tmpPlayer.hash ]){
      this.mapDirective.renderPlayerLocation(tmpPlayer);
      this.taggerHashes[ tmpPlayer.hash ] = true;
    } else {
      this.mapDirective.setPlayerLocation(tmpPlayer);
    }
  }


}