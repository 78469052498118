import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent implements OnInit {
  @Input() link: string;

  private huntforceURI: string = '';

  constructor(
    private configService: ConfigService,
    private domSanitizer: DomSanitizer,
  ) {
    this.huntforceURI = encodeURI( this.configService.getValue('host') );
  }

  ngOnInit() {}

  getFacebookLink(): SafeUrl {
    return this.getSafeUrl('https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(this.link));
  }

  getLinkedinLink(): SafeUrl {
    return this.getSafeUrl('https://www.linkedin.com/shareArticle?mini=true&url='+encodeURI(this.link) + '&title=Hunt%20Force%20Award&summary=&source=' + this.huntforceURI);
  }

  getPinterestLink(): SafeUrl {
    return this.getSafeUrl('https://pinterest.com/pin/create/button/?url=' + encodeURI(this.link) + '&media=' + encodeURI(this.link));
  }

  getTelegramLink(): SafeUrl {
    return this.getSafeUrl('https://t.me/share/url?url=' + encodeURI(this.link));
  }

  getTwitterLink(): SafeUrl {
    return this.getSafeUrl('https://twitter.com/intent/tweet?text=' + encodeURI(this.link));
  }

  getWhatsappLink(): SafeUrl {
    return this.getSafeUrl('https://wa.me/?text=' + encodeURI(this.link));
  }

  getSafeUrl(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl( url );
  }

}
