import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Platform } from '@ionic/angular';
import { LogLevel } from '../interfaces/error-log.interface';
import { ErrorLogService } from './error-log.service';

interface VibrateInterface {
  vibrate(time: number | number[]): any;
}

@Injectable()
export class UtilService {
  constructor(
    private errorLog: ErrorLogService,
    private platform: Platform,
    private vibration: Vibration
  ){}
  
  
  private getVibrateObject(): VibrateInterface {
    if (this.vibration) {
      return this.vibration;
    }

    const navigator = this.getWindowProperty('navigator');
    if (!navigator) {
      return null;
    }

    if (!navigator.vibrate) {
      return null;
    }

    return navigator.vibrate;
  }


  getWindowProperty(propertyName: string) {
    if (!window[propertyName]) {
      return false;
    }

    return window[propertyName];
  }

  
  vibrate(pattern?: number[]) {
    if (!this.vibrateSilentTest()) {
      return;
    }

    const vibrateObject = this.getVibrateObject();
    if (!vibrateObject.vibrate) {
      this.errorLog.add('Util: vibrate: no vibrate method on object', LogLevel.WARN);
      return;
    }

    /*
    let navigator = this.getWindowProperty('navigator');
    if (!navigator) {
      return;
    }

    if (navigator.vibrate) {

      if (!pattern || !pattern.length) {
        pattern = [300];
      }
      navigator.vibrate(pattern);
    } else {
      console.info('Navigator.vibrate does not exist');
    }
    */

    if (!pattern || !pattern.length) {
      pattern = [300];
    }
    
    try {
      if (this.platform.is('ios')) {
        vibrateObject.vibrate(1000);
      } else {
        vibrateObject.vibrate(pattern);
      }
    } catch (error) {
      this.errorLog.add('Util: vibrate: vibrate failed '+error, LogLevel.INFO);
    }
  }


  vibrateSilentTest(): boolean {
    const object = this.getVibrateObject();
    const result = (object !== null);
    return result;
  }

}