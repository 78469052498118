import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable()
export class ConfigService {

  static readonly DEV = 'dev';
  static readonly DEVICE = 'device';
  static readonly DEVICE_BETA = 'deviceBeta';
  static readonly DEVICE_PRODUCTION = 'deviceProduction';
  static readonly NGROK = 'ngrok';
  static readonly WEBAPP_PRODUCTION = 'webAppProduction';

  private config: any = {
    dev : {
      host : 'https://fftt.local/'
    },

    device : {
      host : 'https://huntforce.ddns.net:8003/'
    },

    /**
     * This means the app is running on a device but it's
     * a beta release
     */
    deviceBeta : {
      host : 'https://beta.huntforce.co.uk/'
    },

    /**
     * This means the app is running in production mode on
     * an Android or iPhone
     */
    deviceProduction : {
      host : 'https://play.huntforce.co.uk/'
    },

    ngrok : {
      host : location.protocol + '//' + location.host + '/'
    },

    /**
     * To be phased out
     * This is the web app running in production
     */
    webAppProduction : {
      host : location.protocol + '//' + location.host + '/'
    },

  };

  
  getValue(key: string){
    let env: any = this.config[ this.getEnvironment() ];
    return env[key];
  }


  getEnvironment(): string {
    let result = ConfigService.WEBAPP_PRODUCTION;

    if (location.hostname.includes('.local')){
      result = ConfigService.DEV;
    }

    if (location.hostname.includes('localhost')){
      if (this.isBetaVersion()){
        result = ConfigService.DEVICE_BETA;
      } else if (environment.production) {
        result = ConfigService.DEVICE_PRODUCTION;
      } else {
        result = ConfigService.DEVICE;
      }
    }

    /*
    if (location.hostname.includes('192.168.0.10')){
      return 'device';
    }
    */

    if (location.hostname.includes('ngrok')){
      result = ConfigService.NGROK;
    }

    return result;
  }

  
  isBetaVersion(): boolean {
    /*
    return (
      (location.hostname.substr(0, 4).toLowerCase() === 'beta') ||
      (location.hash.indexOf('beta=beta') != -1)
    );
    */
   return environment.isBeta;
  }

  
  isPWA(): boolean {
    let env = this.getEnvironment();
    let options = [
      ConfigService.DEV, ConfigService.NGROK, ConfigService.WEBAPP_PRODUCTION
    ];

    return (options.includes(env));
  }

}